/*
//@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/angular/libs/datatables.component.scss';
//@import '@core/scss/angular/libs/toastr.component.scss';
@import '@core/scss/angular/libs/flatpickr.component.scss';


body {
  //font-family: 'Montserrat', sans-serif !important;
}
*/
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

.carousel-img-wrapper {
  overflow-anchor: none !important;
}